<template>
  <b-form-group
    :label="label"
  >
    <div style="margin-top: 0px">
      <date-range-picker
        ref="picker"
        v-model="dateRange"
        v-bind="$attrs"
        :locale-data="locale"
        :ranges="currentRanges"
        :opens="opens"
        :auto-apply="true"
        style="padding: 0px"
        v-on="$listeners"
        @select="onSelect"
      >
        <div slot="input" slot-scope="picker" class="date-range-picker-date">
          <span v-if="!picker.startDate" style="color: var(--gray)"> {{ placeholder }} </span>
          <span v-else>
            <div v-if="picker.startDate">
              <i class="glyphicon glyphicon-calendar fa fa-calendar" aria-hidden="true" />
              {{ picker.startDate | moment(formatPresentation) }} - {{ picker.endDate | moment(formatPresentation) }}
            </div>
          </span>
        </div>
        <!--    footer slot-->
        <div slot="footer" class="slot">
          <b-col sm="12" class="text-right pb-1">
            <b-button class="btn-sm" variant="outline-secondary" @click="clear">
              Limpar
            </b-button>
          </b-col>
        </div>

      </date-range-picker>
    </div>
  </b-form-group>
</template>

<script>
import moment from 'moment-timezone'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'I2FormDateRange',

  components: {
    DateRangePicker,
  },
  props: {
    value: {
      type: [Array, Object, String],
      required: false,
    },
    name: {
      type: [String],
      required: false,
      default: '',
    },
    label: {
      type: [String],
      required: false,
      default: '',
    },
    placeholder: {
      type: [String],
      required: false,
      default: '',
    },
    opens: {
      type: [String],
      required: false,
      default: 'right',
    },
  },
  data: vm => ({
    isPlaceholder: false,
    dateRange: {},
    currentDate: {},
    locale: {
      direction: 'ltr',
      format: 'YYYY-MM-DD',
      separator: ' - ',
      applyLabel: 'Aplicar',
      cancelLabel: 'Cancelar',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: 1,
      showWeekNumbers: false,
    },
    formatPresentation: 'DD/MM/YYYY',
    formatModel: 'YYYY-MM-DD',
  }),

  computed: {
    currentRanges() {
      const range = {}
      range.Hoje = [
        moment().toDate(),
        moment().toDate(),
      ]

      range.Ontem = [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ]

      range['Esta Semana'] = [
        moment().startOf('week').toDate(),
        moment().endOf('week').toDate(),
      ]

      range[('Última Semana')] = [
        moment().subtract(1, 'week').startOf('week').toDate(),
        moment().subtract(1, 'week').endOf('week').toDate(),
      ]

      range[('Este mês')] = [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ]

      range[('Últimos 30 dias')] = [
        moment().subtract(30, 'days').toDate(),
        moment().toDate(),
      ]

      range[('Último mês')] = [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ]

      range['Próximo mês'] = [
        moment().add(1, 'month').startOf('month').toDate(),
        moment().add(1, 'month').endOf('month').toDate(),
      ]

      range['Este ano'] = [
        moment().startOf('year').toDate(),
        moment().endOf('year').toDate(),
      ]

      range['Último Ano'] = [
        moment().subtract(1, 'year').startOf('year').toDate(),
        moment().subtract(1, 'year').endOf('year').toDate(),
      ]

      return range
    },
  },

  watch: {
    value(date) {
      if (date === null || date === '') {
        this.dateRange = {
          startDate: null,
          endDate: null,
        }
      }
      this.$emit('input', date)
    },

    dateRange(date) {
      if (date.startDate === null) {
        return
      }
      this.isPlaceholder = false
      this.$emit('input', [
        moment(date.startDate).format(this.formatModel),
        moment(date.endDate).format(this.formatModel),
      ])
    },
  },
  mounted() {
    this.isPlaceholder = !!this.placeholder
    if (!this.placeholder) {
      this.dateRange = {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
      }
    }
    if (this.value) {
      this.dateRange = {
        startDate: this.value[0],
        endDate: this.value[1],
      }
    }
  },
  methods: {
    onSelect(values) {
      this.$emit('onSelect', values)
    },
    clear() {
      this.dateRange.startDate = null
      this.dateRange.endDate = null
      this.$emit('input', null)
      this.$refs.picker.togglePicker(false)
      this.$emit('onClear', true)
    },
  },
}
</script>

<style lang="scss">
.vue-daterange-picker {
  width: 100% !important;
}

.dropdown-menu {
  transform: none;
}

.date-range-picker-date {
  //margin-top: 5px;
  color: #757575;
}

.reportrange-text {
  width: 100%;
  height: 36px;
  border-radius: 5px;
}
</style>
